<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">session management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Create, edit and remove house, committee and joint committee sessions.
          Set title, date, time and a short description for each.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-3">
      <div class="d-flex">
        <v-dialog width="auto" v-model="addDialog" :persistent="addingLoader">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="primary" class="mb-2"
              ><v-icon left>mdi-plus</v-icon> new session</v-btn
            >
          </template>

          <v-card width="500">
            <v-stepper v-model="stepper">
              <v-stepper-header>
                <v-stepper-step step="1"> Session Info </v-stepper-step>
                <v-stepper-step step="2" :complete="stepper > 1">
                  Session Time
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card>
                    <v-card-text>
                      <v-form v-model="addForm1" ref="addForm1">
                        <v-autocomplete
                          v-model="addMeetingBody"
                          outlined
                          dense
                          :rules="[rules.required]"
                          label="Meeting Body"
                          :items="['House', 'Committee', 'Joint Committee']"
                        ></v-autocomplete>

                        <v-autocomplete
                          v-model="addCommitee"
                          outlined
                          :rules="[rules.required]"
                          dense
                          v-if="addMeetingBody !== 'House'"
                          label="Commitee"
                          :items="commiteeItems"
                        ></v-autocomplete>
                        <!-- 
                        <v-autocomplete
                          label="Meeting Type"
                          v-model="addSessionType"
                          :rules="[rules.required]"
                          outlined
                          dense
                          :items="[
                            'Normal Session',
                            'Public Hearing',
                            'Special Seating',
                            'Adhock Session',
                          ]"
                        ></v-autocomplete> -->

                        <v-text-field
                          label="Title"
                          :rules="[rules.required]"
                          outlined
                          dense
                          v-model="addTitle"
                        ></v-text-field>

                        <v-textarea
                          label="Description"
                          outlined
                          dense
                          :rules="[rules.required]"
                          v-model="addDescription"
                        ></v-textarea>
                        <v-file-input
                          label="Session File"
                          dense
                          :rules="[rules.required]"
                          outlined
                          v-model="sessionDocument"
                        ></v-file-input>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="primary" @click="addDialog = false"
                        >cancel</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="stepper = 2"
                        :disabled="!addForm1"
                        >next <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card>
                    <v-card-text>
                      <v-form v-model="addForm2" ref="addForm2">
                        <v-menu
                          v-model="addDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="addDate"
                              label="Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              dense
                              :rules="[rules.required]"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="addDate"
                            @input="addDateMenu = false"
                          ></v-date-picker>
                        </v-menu>

                        <v-menu
                          ref="menu"
                          v-model="addTimeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="addTime"
                              label="Time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              dense
                              outlined
                              :rules="[rules.required]"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="addTimeMenu"
                            v-model="addTime"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menu.save(time)"
                          ></v-time-picker>
                        </v-menu>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="stepper = 1" color="primary">
                        <v-icon left>mdi-chevron-left</v-icon> previous
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        :disabled="!addForm2"
                        :loading="addingLoader"
                        @click="sessionCreate"
                      >
                        create session
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-card>
        <v-data-table
          :search="search"
          :items="sessions"
          :headers="headers"
          class="text-capitalize"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn color="primary" icon @click="editItem(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="(deleteDialog = true), (deletedItemId = item.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.committee`]="{ item }">
            {{ item.committee == null ? "None" : item.committee.name }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ new Date(item.date) | moment("ddd, MMMM Do YYYY") }}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            {{ new Date(`${item.date} ${item.time}`) | moment("calendar") }}
          </template>
        </v-data-table>

        <v-dialog width="auto" v-model="editDialog" :persistent="editLoader">
          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">edit session</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-form v-model="editForm" ref="editForm">
                <v-text-field
                  outlined
                  dense
                  :rules="[rules.required]"
                  prepend-icon="mdi-text"
                  label="Title"
                  v-model="editName"
                ></v-text-field>

                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[rules.required]"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  ref="menu"
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      dense
                      :rules="[rules.required]"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="time"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>

                <v-textarea
                  label="Session Description"
                  v-model="sessionDescription"
                  :rules="[rules.required]"
                  prepend-icon="mdi-text"
                  outlined
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" @click="editDialog = false">
                cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!editForm"
                :loading="editLoader"
                @click="sessionEdit"
              >
                save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="deleteDialog"
          width="auto"
          :persistent="deleteLoader"
        >
          <v-card width="500">
            <v-card-title>
              <p class="mx-auto text-uppercase">delete session</p>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-2">
              <p class="text-subtitle-1 black--text text-center">
                Are you sure you want to delete this session?
              </p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" @click="deleteDialog = false">
                cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                :loading="deleteLoader"
                @click="sessionDelete"
              >
                delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { sessionStore } from "../stores/sessions";
import { commiteeStore } from "../stores/commitees";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      meetingBody: "",
      dateMenu: false,
      date: "",
      timeMenu: false,
      time: "",
      editName: "",
      addDate: new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      addDateMenu: false,
      addTime: new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(11, 5),
      addTimeMenu: false,
      sessionDescription: "",
      addMeetingBody: "",
      addCommitee: "",
      addSessionType: "",
      addTitle: "",
      addDescription: "",
      sessionDocument: null,
      search: "",
      headers: [
        {
          text: "meeting body",
          value: "meeting_body",
          class: "text-uppercase black--text",
        },
        {
          text: "committee",
          value: "committee",
          class: "text-uppercase black--text",
        },
        {
          text: "title",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "time",
          value: "time",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "22%",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      stepper: 1,
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      addingLoader: false,
      editLoader: false,
      deleteLoader: false,
      editForm: false,
      addForm1: false,
      addForm2: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      editedItemId: null,
      deletedItemId: null,
      itemToEdit: null,
    };
  },
  computed: {
    ...mapState(sessionStore, ["session", "sessions"]),
    ...mapState(commiteeStore, [
      "singleCommitteeNames",
      "jointCommitteeNames",
      "commitees",
    ]),
    commiteeItems() {
      let items = [];
      if (this.addMeetingBody == "Committee") items = this.singleCommitteeNames;
      else if (this.addMeetingBody == "Joint Committee")
        items = this.jointCommitteeNames;
      return items;
    },
  },
  methods: {
    ...mapActions(sessionStore, [
      "getSessions",
      "createSession",
      "editSession",
      "deleteSession",
    ]),
    ...mapActions(commiteeStore, ["getCommitees"]),
    sessionCreate() {
      this.addingLoader = true;

      // get committee id
      let comm_id;
      for (let comm of this.commitees) {
        if (comm.name == this.addCommitee) {
          comm_id = comm.id;
        }
      }

      // get meeting body
      let body;
      switch (this.addMeetingBody) {
        case "House":
          body = "house";
          break;
        case "Committee":
          body = "committee";
          break;
        default:
          body = "joint";
      }

      let formdata = new FormData();
      formdata.append("name", this.addTitle);
      formdata.append("description", this.addDescription);
      formdata.append("date", this.addDate);
      formdata.append("time", this.addTime);
      formdata.append("meeting_body", body);
      // check if house session
      if (body != "house") formdata.append("committee_type_id", comm_id);
      formdata.append("session_type_id", 1);
      formdata.append("file_type", "housesession");
      formdata.append("file", this.sessionDocument);

      this.createSession(formdata)
        .then(() => {
          this.$refs.addForm1.reset();
          this.$refs.addForm2.reset();
          this.showSnackBar(true, "session created succesfully");
          this.getSessions();
          this.stepper = 1;
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.addDialog = false;
          this.addingLoader = false;
        });
    },
    editItem(item) {
      this.editedItemId = item.id;
      this.itemToEdit = item;

      // // meeting body
      // switch (item.meeting_body) {
      //   case "house":
      //     this.meetingBody = "House";
      //     break;
      //   case "commitee":
      //     this.meetingBody = "Commitee";
      //     break;
      //   default:
      //     this.meetingBody = "Joint Commitee";
      // }

      this.editName = item.name;
      this.time = new Date(
        new Date(`${item.date} ${item.time}`) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(11, 5);
      this.date = new Date(
        new Date(item.date) - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.sessionDescription = item.description;
      this.editDialog = true;
    },
    sessionEdit() {
      this.editLoader = true;
      this.editSession(this.editedItemId, {
        name: this.editName,
        meeting_body: this.itemToEdit.meeting_body,
        date: this.date,
        time: this.time,
        description: this.sessionDescription,
        committee_type_id: this.itemToEdit.committee_type_id,
        session_type_id: this.itemToEdit.session_type_id,
        file_id: this.itemToEdit.file_id,
      })
        .then(() => {
          this.showSnackBar(true, "succesfully edited session");
          this.getSessions();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.editDialog = false;
          this.editLoader = false;
        });
    },
    sessionDelete() {
      this.deleteLoader = true;
      this.deleteSession(this.deletedItemId)
        .then(() => {
          this.showSnackBar(true, "session deleted succesfully");
          this.getSessions();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.deleteLoader = false;
          this.deleteDialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getSessions();
    this.getCommitees();
  },
};
</script>

<style></style>
